.download-page-container {
    position: relative;
    background-color: rgba(252,236,168,255);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.download-title {
    margin-top: 8vh;
    position: relative;
    margin-bottom: 5vh;
    align-self: center;
    text-align: center;
    font-size: 7.5vh;
    font-family: cursive;
    color: #D184D7;
}

.download-links-box {
    position: relative;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.download-line {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.download-button-color {
    background-color: rgba(156,71,150,255);
}

#downloadButton {
    display: flex;
    justify-content: center; 
    align-items: center;
    text-align: center;
}
.os-type {
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
