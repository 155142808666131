@font-face {
  font-family: 'HeartBubbles';
  src: url('../Fonts/HeartBubbles.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.header-container {
  background-color: rgb(255, 211, 244);
  position: relative;
  height: 10vh;
  width: 100%;
  /* margin-bottom: 7.5vh; */
  align-content: center;
}

.logo {
  width: 60px;
  height: 60px;
  margin-left: 10px;
}

.logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; 
  margin-left: 10px;
}

.header-option-text {
  /*
  'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif
  #000000
  */
  font-family: 'HeartBubbles', cursive;
  color: #9c4796;
  font-size: 3.5vh;

  @media (max-width: 767px) {
    font-size: 2.5vh;
  }
}

.header-option-container {
  position: relative;
  padding: 0 1px; 
  margin: 0 10px;  
}

.header-option-text:hover {
  cursor: pointer;
  color: rgba(156, 71, 150, 255);
}

/* Ideas: */
/*   - A feel like the navbar still looks kind of slapped on there. Maybe adding the ufosc and bytes of love logo in the corners would help */
