*{
    font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
}

.home-page-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #FEF7D8;

    text-align: center;
}

#heroImage{
    width: 100%;
}

/* Hero Image Styles */
.picture-carousel { 
    position: relative;
    align-self: center;
    text-align: center;
    align-content: center;
    width: 100vw;
    /* max-height: 80vh !important; */
    /* height: 65vh; */
    /* outline: 2px solid red;  */
}

#descriptionSection{
    background: #D184D7;
    margin: 30px;
    border-radius: 60px;
    color: white;
    padding: 50px;
}

#descDivi{
    width: 20%;
    margin: auto;
}

#download #descDivi{
    padding-bottom: 30px;
}

#aboutHeading {
    font-family: cursive;
    font-size: 4em;
    
}
#description-text {
    margin-top: 1vh;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 2.5vh;
}

#meetChars{
    margin: 30px;
}

#meetHeading{
    font-family: cursive;
    font-size: calc(6vw + 2vh);

    padding-left: 20px;
    padding-right: 20px;


    align-content: center;
    align-items: center;
}

#bytesName{
    color: #D184D7;
    font-family: cursive;
}

/* Download Styles */
#download #aboutHeading{
    padding-bottom: 50px;
}

#downloadCol{
    columns: 3;
    padding-bottom: 30px;

    margin: 10px;
}

.column{
    background-color: white;
    padding: 30px;
    margin-left: 20px;
    border-radius: 40px;
    margin-right: 20px;
    border: pink, 2px, dashed;
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 40vh;

    align-items: center;
}

.logo{
    height: 8vh;
}

#downloadLabel{
    
    font-size: calc(4vw + 1vh);
    padding-bottom: 20px;
    
}

#downloadButton{


    background: #C0419D;
    color: white;
    padding: 10px;
    border-radius: 30px;
    margin-top: 10px;

    height: 7vh;
    font-size: 21px;

    width: 100%
}

#downloadButton a{
    font-size: calc(2vw + 0.5vh);
}

#musicButton{
    z-index: 1;
}

.Carousel-Wrapper {
    height: calc(75vh + 10vw);
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 3vh auto 10px auto;
    position: relative; /* Relative positioning for the button container */
    
    border-radius: 5%;
    background-size: cover;
    background-size: no-repeat;
}